import { useHistory, useLocation } from 'react-router-dom';

export function useUrlParams<T extends { [key: string]: string | boolean }>(): {
  current: T;
  setParams: (params: Partial<T>) => void;
} {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const current: { [key: string]: string | boolean } = {};
  for (const [key, value] of searchParams.entries()) {
    if (value === 'true') {
      current[key] = true;
    } else if (value === 'false') {
      current[key] = false;
    } else {
      current[key] = value;
    }
  }

  const history = useHistory();

  const setParams = (newState: Partial<T>) => {
    const newSearchParams = new URLSearchParams(location.search);
    Object.entries(newState).forEach(([key, value]) => {
      if (value !== undefined) {
        newSearchParams.set(key, String(value));
      } else {
        newSearchParams.delete(key);
      }
    });
    history.push(`${location.pathname}?${newSearchParams.toString()}`);
  };

  return {
    current: current as T,
    setParams
  };
}
