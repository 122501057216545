import { makeQueryString, pack } from '@/utils';
import { api, binaryDataApi } from './api';

import { FileCategory } from '@/enums/fileTypes';
import { Company } from '@/types/company';
import { DownloadedFile } from '@/types/file';
import { Insurance } from '@/types/insurance';
import { WithPaginationMetadata } from '@/utils/types';

export interface UpdateFilePayload {
  fileId: string;
  displayName?: string;
  visibleToUser?: boolean;
  category?: string;
  description?: string;
}

type FileMetadata = {
  customer?: {
    lastname?: string;
  };
  policy?: {
    id?: string;
    risk?: string;
    premium?: string;
  };
  insurer?: {
    name?: string;
  };
};

type BaseFile = {
  fileId: string;
  name: string;
  displayName: string;
  type: string;
  archive: boolean;
  createdAt: string;
  updatedAt: string;
  visibleToUser: boolean;
  category: FileCategory | null;
  storageKey: string;
  description: string | null;
  metadata: FileMetadata | null;
};

type FileRelation = 'insurance' | 'company';

type UnprocessedFile = BaseFile & {
  isProcessed: false;
} & Partial<{
    belongsToUuid: string;
    belongsToTable: FileRelation;
  }>;

type ProcessedFile = BaseFile & {
  isProcessed: true;
} & {
  belongsToUuid: string;
  belongsToTable: FileRelation;
};

// File is reserved keyword
export type GenericFile = UnprocessedFile | ProcessedFile;

export const postFiles = (
  id: string,
  files: File[],
  type: string,
  category?: string
) => {
  const formData = new FormData();

  pack(files).forEach((file) => formData.append('file', file as string | Blob));

  return api.post(
    `/file/${id}${makeQueryString({ type, category })}`,
    formData,
    {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>'
      }
    }
  );
};

export const getFileCategories = (): Promise<string[]> =>
  api.get('/file/categories');

export const updateFile = ({
  fileId,
  displayName,
  visibleToUser,
  category,
  description
}: UpdateFilePayload) =>
  api.patch(`/file/${fileId}`, {
    displayName,
    visibleToUser,
    category,
    description
  });

export const downloadFile = (name: string) =>
  api.get<DownloadedFile>(`file${makeQueryString({ name })}`);

export const archiveFileById = (id: string) => api.delete(`/file/${id}`);

export const restoreFileById = (id: string) =>
  api.patch(`admin/file/${id}/restore`);

export type GenericFileWithRelatedEntities = GenericFile &
  Partial<{
    company: {
      name: string;
    };
    insurance: {
      categoryId: string;
      policyNumber: string;
    };
    carrier: {
      name: string;
    };
  }>;

export const getAllFilesWithRelatedEntities = async (params: {
  page: number;
  pageSize: number;
  searchQuery: string;
  processed: boolean;
  archived: boolean;
}) =>
  api.get<WithPaginationMetadata<GenericFileWithRelatedEntities>>(
    'admin/file',
    {
      params
    }
  );

type FileRelatedEntities = {
  company?: Pick<
    Company,
    | 'bannerUrl'
    | 'businessType'
    | 'companyId'
    | 'companyWonAt'
    | 'createdAt'
    | 'createdBy'
    | 'foundedDate'
    | 'isHeadquarters'
    | 'isTest'
    | 'logoUrl'
    | 'lookingFor'
    | 'name'
    | 'poaSigned'
    | 'updatedAt'
    | 'address'
  >;
  insurance?: Insurance;
};

export type FileWithRelatedEntities = {
  file: GenericFile;
} & FileRelatedEntities;

export const getFileWithRelatedEntitiesById = async (fileId: string) =>
  api.get<FileWithRelatedEntities>(`admin/inbox-file/${fileId}`);

export const processFile = async (
  fileId: string,
  options: {
    sendNotification: boolean;
  }
) => api.patch(`admin/file/${fileId}/process`, options);

export const getFileContent = async (fileId: string) => {
  const response = await binaryDataApi.get(`/admin/file/${fileId}/content`);

  return response.data;
};

export const uploadUnprocessedFiles = async (files: File[]) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('file', file);
  });

  return api.post('/admin/inbox-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export type AssignFilePayload = {
  belongsToUuid: string;
  belongsToTable: FileRelation;
};

export const assignFile = async (
  inboxFileId: string,
  data: AssignFilePayload
): Promise<{
  success: true;
}> => {
  return api.patch(`/admin/inbox-file/${inboxFileId}/assignment`, data);
};
