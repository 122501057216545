import { Checkbox, Divider, Radio } from 'antd';

type Props = {
  handleTabChange: (value: boolean) => void;
  handleArchiveChange: (checked: boolean) => void;
  processed: boolean;
  archive: boolean;
};

export function FileListViewOptions({
  handleTabChange,
  handleArchiveChange,
  processed,
  archive
}: Props) {
  return (
    <div>
      <Radio.Group
        onChange={(e) => handleTabChange(e.target.value)}
        value={processed}
      >
        <Radio.Button value={false}>Inbox</Radio.Button>
        <Radio.Button value={true}>Documents</Radio.Button>
      </Radio.Group>
      <Divider type="vertical" />
      <Checkbox
        checked={archive}
        onChange={(e) => handleArchiveChange(e.target.checked)}
      >
        Archive
      </Checkbox>
    </div>
  );
}
