import {
  archiveFileById,
  assignFile,
  AssignFilePayload,
  downloadFile,
  getAllFilesWithRelatedEntities,
  getFileCategories,
  getFileContent,
  getFileWithRelatedEntitiesById,
  postFiles,
  processFile,
  restoreFileById,
  updateFile,
  UpdateFilePayload,
  uploadUnprocessedFiles
} from '@/api';
import { previewResponseFile, useInvalidateOnSuccessMutation } from '@/utils';
import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';

export const useUploadFiles = (
  id: string,
  entity: QueryKey,
  type: string,
  category?: string
) =>
  useInvalidateOnSuccessMutation([entity, id], (files: File[]) =>
    postFiles(id, files, type, category)
  );

export const useDownloadFile = () =>
  useMutation(downloadFile, {
    onSuccess: previewResponseFile
  });

export const useFile = (name: string) =>
  useQuery(['file', name], () => downloadFile(name));

export const useUpdateFile = (fileId: string) => {
  const queryClient = useQueryClient();

  return useMutation((file: UpdateFilePayload) => updateFile(file), {
    onSuccess: () => {
      queryClient.invalidateQueries([keys.files, fileId]);
      queryClient.invalidateQueries([keys.files, 'infinite']);
      queryClient.invalidateQueries(['history', 'file', fileId]);
    }
  });
};

export const useUpdateInsuranceFile = (insuranceId: string) => {
  const queryClient = useQueryClient();

  return useMutation((file: UpdateFilePayload) => updateFile(file), {
    onSuccess: () => {
      queryClient.invalidateQueries(['insurance', insuranceId]);
    }
  });
};

export const useFileCategories = () =>
  useQuery<string[]>('file-categories', getFileCategories);

export const useArchiveFile = (entity: QueryKey, id?: string) =>
  useInvalidateOnSuccessMutation(entity, (overrideId) =>
    archiveFileById(overrideId || id)
  );

export const useRestoreFile = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => restoreFileById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([keys.files, id]);
      queryClient.invalidateQueries([keys.files]);
    }
  });
};

const keys = {
  files: 'files'
};

export const useGetGenericFilesWithRelatedEntities = (payload: {
  page: number;
  pageSize: number;
  searchQuery: string;
  processed: boolean;
  archived: boolean;
}) =>
  useQuery(
    [
      keys.files,
      payload.page,
      payload.pageSize,
      payload.searchQuery,
      payload.processed,
      payload.archived
    ],
    () => getAllFilesWithRelatedEntities(payload)
  );

export const useGetGenericFilesWithRelatedEntitiesInfinite = ({
  pageSize,
  searchQuery,
  processed,
  archived
}: {
  pageSize: number;
  searchQuery: string;
  processed: boolean;
  archived: boolean;
}) =>
  useInfiniteQuery({
    queryFn: ({ pageParam = 1 }) =>
      getAllFilesWithRelatedEntities({
        page: pageParam,
        pageSize,
        searchQuery,
        processed,
        archived
      }),

    queryKey: [
      keys.files,
      'infinite',
      searchQuery,
      pageSize,
      processed,
      archived
    ],
    getNextPageParam: (lastPage) => {
      const { meta } = lastPage;
      const hasNextPage = meta.currentPage * meta.pageSize < meta.totalItems;
      return hasNextPage ? meta.currentPage + 1 : undefined;
    }
  });

export const useUploadUnprocessedFiles = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (files: File[]) => uploadUnprocessedFiles(files),
    onSuccess: () => {
      queryClient.invalidateQueries([keys.files]);
    }
  });
};

export const useGetFileContent = (fileId: string) =>
  useQuery([keys.files, 'content', fileId], () => getFileContent(fileId));

export const useGetFileWithRelatedEntities = (fileId: string) =>
  useQuery([keys.files, fileId], () => getFileWithRelatedEntitiesById(fileId));

export const useProcessFile = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { sendNotification: boolean }) =>
      processFile(id, options),
    onSuccess: () => {
      queryClient.invalidateQueries([keys.files]);
      queryClient.invalidateQueries(['history', 'file', id]);
    }
  });
};

export const useAssignFile = (inboxFileId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: AssignFilePayload) => assignFile(inboxFileId, data),
    onSuccess: () => {
      queryClient.invalidateQueries([keys.files, inboxFileId]);
      queryClient.invalidateQueries(['history', 'file', inboxFileId]);
    }
  });
};
