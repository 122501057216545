import { Layout, Skeleton, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useParams } from 'react-router-dom';

import { PdfEditorView } from '@/components/PdfEditorView/PdfEditorView';
import {
  useGetFileContent,
  useGetGenericFilesWithRelatedEntitiesInfinite
} from '@/services';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import { useDebouncedCallback } from 'use-debounce';
import { DocumentUrlParams } from '../Page';
import { FileListSider } from './FileListSider';
import { Sidebar } from './Sidebar/Sidebar';

export function FileOverview() {
  const { fileId } = useParams<{ fileId: string }>();
  const { current, setParams } = useUrlParams<DocumentUrlParams>();

  const { data: pdfData, isLoading: isPdfLoading } = useGetFileContent(fileId);

  const debouncedSetSearchQuery = useDebouncedCallback(
    (value: string) => setParams({ ...current, searchQuery: value }),
    300
  );

  const handleProcessedChange = (value: boolean) => {
    setParams({ processed: value });
  };

  const handleArchiveChange = (value: boolean) => {
    setParams({ archive: value });
  };

  const {
    data: files,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useGetGenericFilesWithRelatedEntitiesInfinite({
    searchQuery: current.searchQuery ?? '',
    pageSize: 25,
    processed: current.processed,
    archived: current.archive
  });

  const loadMore = () => {
    if (!isLoading && hasNextPage) {
      fetchNextPage();
    }
  };

  const allFiles = files?.pages.flatMap((page) => page.data) ?? [];

  return (
    <Layout style={{ flexDirection: 'row', margin: '-56px -40px' }}>
      <FileListSider
        searchQuery={current.searchQuery ?? ''}
        isLoading={isLoading}
        allFiles={allFiles}
        hasNextPage={hasNextPage ?? false}
        onSearch={debouncedSetSearchQuery}
        onLoadMore={loadMore}
        selectedFileId={fileId}
        processed={current.processed}
        archive={current.archive}
        onProcessedChange={handleProcessedChange}
        onArchiveChange={handleArchiveChange}
      />

      {isPdfLoading ? (
        <Skeleton active paragraph={{ rows: 16 }} />
      ) : pdfData ? (
        <PdfEditorView pdf={pdfData} fileName={fileId} />
      ) : (
        <Content
          style={{
            height: 'calc(100vh - 86px)',
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography.Paragraph>
            Could not load PDF preview.
          </Typography.Paragraph>
        </Content>
      )}

      <Sidebar unprocessedFiles={allFiles} />
    </Layout>
  );
}
