import { useCallback, useRef, useState } from 'react';

import { PDFPageProxy } from 'pdfjs-dist';
import { getCssVar } from '@/utils/cssVariables';
import { cssVariables } from '@/enums/cssVariables';

export const usePdfTopbar = () => {
  const [scale, setScale] = useState(0);
  const zoomIn = () => setScale((prev) => prev + 0.25);
  const zoomOut = () => setScale((prev) => Math.max(0.5, prev - 0.25));
  const pageWrapperRef = useRef<HTMLDivElement>(null);

  const onFirstPageLoad = useCallback((page: PDFPageProxy) => {
    if (!pageWrapperRef.current) return;

    const { width: wrapperWidth } =
      pageWrapperRef.current?.getBoundingClientRect() || {};
    const { width: pageWidth } = page.getViewport({ scale: 1 });

    if (pageWidth && wrapperWidth) {
      const documentPadding = parseFloat(
        getCssVar(cssVariables.pdfEditorPadding)
      );

      const newScale = (wrapperWidth - documentPadding * 2) / pageWidth;
      setScale(newScale);
    }
  }, []);

  return {
    scale,
    zoomIn,
    zoomOut,
    onFirstPageLoad,
    pageWrapperRef
  };
};
