import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import './styles.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// @ts-expect-error manually copied, please check craco.config.js
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function range(count: number): number[] {
  return Array.from({ length: count }, (_v, k) => k + 1);
}

export const PdfDocument = ({
  pdf,
  width,
  className,
  scale = 1,
  onFirstPageLoad
}: {
  pdf: Blob;
  width?: number;
  className?: string;
  scale?: number;
  onFirstPageLoad?: (page: PDFPageProxy) => void;
}) => {
  const [pagesCount, setPagesCount] = useState<number>(0);
  async function onDocumentLoadSuccess(
    document: PDFDocumentProxy
  ): Promise<void> {
    onFirstPageLoad && onFirstPageLoad(await document.getPage(1));
    setPagesCount(document.numPages);
  }

  return (
    <div className={className ?? 'pdf-document-wrapper'}>
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Boolean(scale) &&
          range(pagesCount).map((page) => (
            <Page width={width} key={page} pageNumber={page} scale={scale} />
          ))}
      </Document>
    </div>
  );
};
