import { Button, Form, Input, Select, Switch } from 'antd';
import { useParams } from 'react-router-dom';

import { FileWithRelatedEntities } from '@/api';
import { fileCategories } from '@/enums/fileTypes';
import { useArchiveFile, useRestoreFile, useUpdateFile } from '@/services';
import { format } from 'date-fns';
import { useState } from 'react';
import { FileAddOutlined, InboxOutlined } from '@ant-design/icons';

export const GeneralTab = ({ file }: FileWithRelatedEntities) => {
  const { fileId } = useParams<{ fileId: string }>();
  const { mutate: updateFile, isLoading: isUpdating } = useUpdateFile(fileId);
  const { mutate: archiveFile, isLoading: isArchiving } = useArchiveFile(
    ['files', fileId], // TODO: this should be in service
    fileId
  );

  const restoreMutation = useRestoreFile(fileId);

  const [form] = Form.useForm();

  const categoryOptions = Object.entries(fileCategories).map(
    ([key, value]) => ({
      value: key,
      label: value
    })
  );

  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const handleStartEditing = () => {
    form.setFieldValue('description', file.description || '');
    setIsEditingDescription(true);
  };

  const handleDescriptionSubmit = async (submitData: {
    description: string;
  }) => {
    if (submitData.description !== file.description) {
      updateFile({ fileId, description: submitData.description });
    }
    setIsEditingDescription(false);
  };

  return (
    <>
      <p style={{ fontWeight: 500, marginBottom: '8px' }}>Upload date</p>
      <p>{format(new Date(file.createdAt), 'MMM d, h:mm a')}</p>

      <label
        htmlFor="category-select"
        style={{ marginBottom: '8px', fontWeight: 500, display: 'block' }}
      >
        Category
      </label>
      <Select
        id="category-select"
        value={file.category}
        placeholder="Select Category"
        style={{ width: '100%' }}
        onChange={(category: string) => {
          updateFile({ fileId, category });
        }}
        options={categoryOptions}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '')
            .toLowerCase()
            .localeCompare((optionB?.label ?? '').toLowerCase())
        }
        showSearch
        loading={isUpdating}
      />

      <label
        htmlFor="description-textarea"
        style={{
          marginBottom: '8px',
          marginTop: '16px',
          fontWeight: 500,
          display: 'block'
        }}
      >
        Description
      </label>

      {isEditingDescription ? (
        <Form form={form} layout="vertical" onFinish={handleDescriptionSubmit}>
          <Form.Item name="description">
            <Input.TextArea
              id="description-textarea"
              placeholder="Enter description"
              autoSize={{ minRows: 3, maxRows: 6 }}
              style={{ marginBottom: '16px' }}
            />
          </Form.Item>

          <Button loading={isUpdating} type="primary" htmlType="submit">
            Save
          </Button>
          <Button onClick={() => setIsEditingDescription(false)}>Cancel</Button>
        </Form>
      ) : (
        <>
          {file.description ? (
            <p style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
              {file.description}
            </p>
          ) : (
            <p style={{ color: 'gray' }}>No description</p>
          )}
          <Button onClick={handleStartEditing}>Edit</Button>
        </>
      )}

      <label
        htmlFor="visible-to-user-checkbox"
        style={{
          marginBottom: '8px',
          marginTop: '16px',
          fontWeight: 500,
          display: 'block',
          cursor: 'pointer'
        }}
      >
        Visible to user
      </label>
      <Switch
        id="visible-to-user-checkbox"
        checked={file.visibleToUser}
        onChange={(checked) => {
          updateFile({ fileId, visibleToUser: checked });
        }}
      />

      <div
        style={{
          marginTop: '16px'
        }}
      >
        {file.archive ? (
          <Button
            loading={restoreMutation.isLoading}
            onClick={() => restoreMutation.mutate()}
            icon={<FileAddOutlined />}
            type="primary"
          >
            Restore
          </Button>
        ) : (
          <Button
            loading={isArchiving}
            onClick={() => archiveFile(fileId)}
            icon={<InboxOutlined />}
            type="dashed"
            danger
          >
            Archive
          </Button>
        )}
      </div>
    </>
  );
};
