import { Button, message } from 'antd';
import React, { useCallback } from 'react';

import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { downloadPdfBlob, printPdfBlob } from '@/utils/blobPdf';

import './styles.scss';

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 3;

interface Props {
  pdf: Blob;
  fileName: string;
  zoomOut: () => void;
  zoomIn: () => void;
  scale: number;
}

export const PdfEditorViewBar = ({
  pdf,
  fileName,
  zoomOut,
  zoomIn,
  scale
}: Props) => {
  const handleDownload = useCallback(() => {
    message.info('PDF download initiated');
    downloadPdfBlob(pdf, fileName);
  }, [fileName, pdf]);
  const handlePrint = useCallback(() => {
    message.info('Printing initiated');
    printPdfBlob(pdf);
  }, [pdf]);

  return (
    <>
      <div className="pdf-editor-view-bar__bar-center">
        <Button onClick={zoomOut} disabled={scale <= MIN_ZOOM}>
          -
        </Button>
        <div className="pdf-editor-view-bar__scale-label">
          {Math.round(scale * 100)}%
        </div>
        <Button onClick={zoomIn} disabled={scale >= MAX_ZOOM}>
          +
        </Button>
      </div>

      <div className="pdf-editor-view-bar__bar-right">
        <Button icon={<PrinterOutlined />} onClick={handlePrint} />
        <Button icon={<DownloadOutlined />} onClick={handleDownload} />
      </div>
    </>
  );
};
