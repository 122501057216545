import { GenericFileWithRelatedEntities } from '@/api';
import { SearchAwareLink } from '@/components/SearchAwareLink';
import { fileCategories } from '@/enums/fileTypes';
import { paths } from '@/routes';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Input, List, Skeleton } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { format } from 'date-fns';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FileListViewOptions } from '../FileListViewOptions';
import { FileCard } from './FileCard';

const formatFileDescription = (
  file: GenericFileWithRelatedEntities
): string => {
  const parts = [
    format(new Date(file.createdAt), 'MMM d, yyyy'),
    file.metadata?.policy?.id || 'No Policy ID',
    file.metadata?.customer?.lastname || 'No Customer Name'
  ];

  return parts.join(' • ');
};

interface FileListSiderProps {
  searchQuery: string;
  isLoading: boolean;
  allFiles: GenericFileWithRelatedEntities[];
  hasNextPage: boolean;
  onSearch: (value: string) => void;
  onLoadMore: () => void;
  selectedFileId?: string;
  processed: boolean;
  archive: boolean;
  onProcessedChange: (value: boolean) => void;
  onArchiveChange: (value: boolean) => void;
}

export function FileListSider({
  searchQuery,
  isLoading,
  allFiles,
  hasNextPage,
  onSearch,
  onLoadMore,
  selectedFileId,
  processed,
  archive,
  onProcessedChange,
  onArchiveChange
}: FileListSiderProps) {
  useEffect(() => {
    if (selectedFileId && !isLoading) {
      const activeCard = document.getElementById(`file-card-${selectedFileId}`);
      if (activeCard) {
        activeCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedFileId, isLoading]);

  return (
    <Sider
      theme="light"
      width={300}
      breakpoint="lg"
      collapsedWidth={0}
      style={{ borderRight: '1px solid #f0f0f0' }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          padding: '8px',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <FileListViewOptions
          handleTabChange={onProcessedChange}
          processed={processed}
          archive={archive}
          handleArchiveChange={onArchiveChange}
        />
        <SearchAwareLink to={paths.documents} style={{ lineHeight: 0 }}>
          <Button icon={<LeftOutlined />} size="large" />
        </SearchAwareLink>
        <Input.Search
          defaultValue={searchQuery}
          placeholder="Search documents"
          allowClear
          loading={isLoading}
          onChange={(e) => onSearch(e.target.value)}
          size="large"
          style={{ flex: '1 1 150px' }}
        />
      </div>
      <div className="file-list-container" id="file-list">
        {isLoading ? (
          <div style={{ padding: '8px' }}>
            <Skeleton active paragraph={{ rows: 20 }} />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={allFiles.length}
            next={onLoadMore}
            hasMore={hasNextPage}
            loader={<Skeleton active paragraph={{ rows: 1 }} />}
            scrollableTarget="file-list"
          >
            <List
              dataSource={allFiles}
              renderItem={(file: GenericFileWithRelatedEntities) => (
                <List.Item
                  style={{ padding: 0 }}
                  id={`file-card-${file.fileId}`}
                >
                  <FileCard
                    fileId={file.fileId}
                    title={
                      file.category
                        ? fileCategories[file.category]
                        : 'Uncategorized'
                    }
                    description={formatFileDescription(file)}
                    isActive={file.fileId === selectedFileId}
                  />
                </List.Item>
              )}
              style={{ padding: '8px' }}
            />
          </InfiniteScroll>
        )}
      </div>
    </Sider>
  );
}
