import { useUploadUnprocessedFiles } from '@/services';
import { useLocalStorage } from '@/utils';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React from 'react';
import { FileList } from './FileList';
import { FileListViewOptions } from './FileListViewOptions';
import './styles.scss';

export type DocumentUrlParams = {
  page: string;
  pageSize: string;
  searchQuery: string;
  processed: boolean;
  archive: boolean;
};

const DEFAULT_PARAMS: DocumentUrlParams = {
  page: '1',
  pageSize: '10',
  searchQuery: '',
  processed: false,
  archive: false
};

export const Documents = () => {
  const { current, setParams } = useUrlParams<DocumentUrlParams>();
  const [storedPageSize, setStoredPageSize] = useLocalStorage(
    'fileListPageSize',
    DEFAULT_PARAMS.pageSize
  );

  // Initialize URL parameters on mount
  React.useEffect(() => {
    const isParamMissing = Object.keys(DEFAULT_PARAMS).some(
      (key) => current[key as keyof DocumentUrlParams] === undefined
    );

    if (isParamMissing) {
      setParams({
        ...DEFAULT_PARAMS,
        ...current,
        pageSize: current.pageSize || storedPageSize || DEFAULT_PARAMS.pageSize
      });
    }
  }, []);

  const [fileList, setFileList] = React.useState<RcFile[]>([]);

  const resetFileList = () => setFileList([]);

  const uploadInboxFilesMutation = useUploadUnprocessedFiles();

  const handleUpload = () => {
    uploadInboxFilesMutation.mutateAsync(fileList).then(resetFileList);
  };

  const handleTabChange = (value: boolean) => {
    setParams({ processed: value, page: '1' });
  };

  const handleArchiveChange = (checked: boolean) => {
    setParams({ archive: checked, page: '1' });
  };

  const handleSearchChange = (value: string) => {
    setParams({ searchQuery: value, page: '1' });
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setParams({
      page: String(page),
      pageSize: String(pageSize)
    });
    setStoredPageSize(String(pageSize));
  };

  return (
    <>
      <div className="documents-page">
        <h1 className="documents-page-title">
          {current.processed ? 'Documents' : 'File Inbox'}
        </h1>
        <div className="upload-section">
          <FileListViewOptions
            handleTabChange={handleTabChange}
            processed={current.processed || DEFAULT_PARAMS.processed}
            archive={current.archive || DEFAULT_PARAMS.archive}
            handleArchiveChange={handleArchiveChange}
          />
          <Divider type="vertical" />
          <div className="upload-section-right">
            <Upload
              beforeUpload={(file: RcFile) => {
                setFileList((prev) => [...prev, file]);
                /**
                 * Prevent default behavior of the upload component;
                 * We want to handle the upload logic manually
                 */
                return false;
              }}
              fileList={fileList}
              accept=".pdf,.zip,.xml,application/zip,application/x-zip-compressed"
              multiple
              onRemove={(file) => {
                setFileList((prev) => prev.filter((f) => f !== file));
              }}
            >
              <Button icon={<UploadOutlined />}>Select Documents</Button>
            </Upload>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploadInboxFilesMutation.isLoading}
            >
              {uploadInboxFilesMutation.isLoading ? 'Uploading' : 'Upload'}
            </Button>
          </div>
        </div>

        <FileList
          page={Number(current.page || DEFAULT_PARAMS.page)}
          pageSize={Number(current.pageSize || DEFAULT_PARAMS.pageSize)}
          searchQuery={current.searchQuery}
          processed={current.processed}
          archived={current.archive}
          onSearchChange={handleSearchChange}
          onPaginationChange={handlePaginationChange}
        />
      </div>
    </>
  );
};
