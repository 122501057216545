import { GenericFile, GenericFileWithRelatedEntities } from '@/api';
import { SearchAwareLink } from '@/components/SearchAwareLink';
import { PlainCategoryLabelMap } from '@/enums';
import { fileCategories } from '@/enums/fileTypes';
import {
  useArchiveFile,
  useGetGenericFilesWithRelatedEntities,
  useRestoreFile
} from '@/services';
import {
  FileAddOutlined,
  InboxOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Empty, Table, Tag, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { useDebounce } from 'use-debounce';
import { FileListFilters } from './FileListFilters';

const columns: ColumnsType<GenericFileWithRelatedEntities> = [
  {
    title: 'Company',
    align: 'center',
    render: (_, record) => {
      if (record.company?.name) {
        return (
          <SearchAwareLink to={`/documents/${record.fileId}`}>
            {record.company?.name}
          </SearchAwareLink>
        );
      }

      if (record.metadata?.customer?.lastname) {
        return (
          <SearchAwareLink to={`/documents/${record.fileId}`}>
            <Typography.Paragraph style={{ color: 'gray', margin: 0 }}>
              {record.metadata?.customer?.lastname}
            </Typography.Paragraph>
          </SearchAwareLink>
        );
      }

      return (
        <SearchAwareLink to={`/documents/${record.fileId}`}>
          [Empty]
        </SearchAwareLink>
      );
    }
  },
  {
    title: 'Insurance Type',
    align: 'center',
    render: (_, record) =>
      record.insurance?.categoryId &&
      PlainCategoryLabelMap[record.insurance?.categoryId]
  },
  {
    title: 'Policy Number',
    render: (_, record) => {
      if (record.insurance?.policyNumber) {
        return record.insurance?.policyNumber;
      }

      if (record.metadata?.policy?.id) {
        return (
          <Typography.Paragraph style={{ color: 'gray', margin: 0 }}>
            {record.metadata?.policy?.id}
          </Typography.Paragraph>
        );
      }

      return <></>;
    }
  },
  {
    title: 'Category',
    render: (_, record) => (
      <Tag color={record.category ? 'blue' : 'default'}>
        {record.category
          ? fileCategories[record.category as keyof typeof fileCategories]
          : 'Uncategorized'}
      </Tag>
    )
  },
  {
    title: 'Carrier',
    render: (_, record) => {
      if (record.carrier?.name) {
        return record.carrier?.name;
      }

      if (record.metadata?.insurer?.name) {
        return (
          <Typography.Paragraph style={{ color: 'gray', margin: 0 }}>
            {record.metadata?.insurer?.name}
          </Typography.Paragraph>
        );
      }

      return <></>;
    }
  },
  {
    title: 'Risk',
    render: (_, record) => record.metadata?.policy?.risk
  },
  {
    title: 'Uploaded at',
    dataIndex: 'createdAt',
    width: '150px',
    render: (_, record) => format(new Date(record.createdAt), 'MMM d, h:mm a')
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    width: '150px',
    render: (_, record) => format(new Date(record.updatedAt), 'MMM d, h:mm a')
  },
  {
    title: 'Actions',
    align: 'center',
    render: (_, record) => <FileRecordActions record={record} />
  }
];

interface FileListProps {
  page: number;
  pageSize: number;
  searchQuery?: string;
  processed: boolean;
  archived: boolean;
  onSearchChange: (value: string) => void;
  onPaginationChange: (page: number, pageSize: number) => void;
}

export function FileList({
  page,
  pageSize,
  searchQuery,
  processed,
  archived,
  onSearchChange,
  onPaginationChange
}: FileListProps) {
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const { data: files, isLoading } = useGetGenericFilesWithRelatedEntities({
    page,
    pageSize,
    searchQuery: debouncedSearchQuery ?? '',
    processed,
    archived
  });

  if (!isLoading && !debouncedSearchQuery && files?.data?.length === 0) {
    return (
      <div className="well-done">
        <Empty
          description={
            processed
              ? 'No documents found.'
              : 'Well done! No files to process.'
          }
        />
      </div>
    );
  }

  return (
    <>
      <Table
        title={() => (
          <FileListFilters
            searchQuery={searchQuery}
            isLoading={isLoading}
            onSearchChange={onSearchChange}
          />
        )}
        rowKey="fileId"
        columns={columns}
        dataSource={files?.data}
        loading={isLoading}
        pagination={{
          total: files?.meta.totalItems,
          pageSize: files?.meta.pageSize,
          current: files?.meta.currentPage,
          showSizeChanger: true
        }}
        size="small"
        onChange={(paginationChange) => {
          onPaginationChange(
            paginationChange.current ?? 1,
            paginationChange.pageSize ?? pageSize
          );
        }}
      />
    </>
  );
}

export function FileRecordActions({ record }: { record: GenericFile }) {
  const archiveFileMutation = useArchiveFile(
    ['files', record.fileId], // TODO: this should be in service
    record.fileId
  );

  const restoreFileMutation = useRestoreFile(record.fileId);

  const archiveItem: ItemType = {
    key: 'archive',
    label: 'Archive',
    icon: <InboxOutlined />,
    onClick: () => archiveFileMutation.mutate(record.fileId)
  };

  const restoreItem: ItemType = {
    key: 'restore',
    label: 'Restore',
    icon: <FileAddOutlined />,
    onClick: () => restoreFileMutation.mutate()
  };

  const items = [record.archive ? restoreItem : archiveItem];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button
        type="text"
        icon={<MenuOutlined style={{ fontSize: '18px' }} />}
      />
    </Dropdown>
  );
}
